<template>
  <div class="product_jeapp_content">
    <div class="box_top">
      <Header class="page_hea" />
      <div class="top_tlite">
        <p class="title">
          JEAPP
        </p>
        <p class="subtitle">
          企业专属移动应用
        </p>
        <div
          class="btn"
          @click="openqq"
        >
          立即咨询
        </div>
      </div>
      <div class="top_img">
        <img
          v-lazy="jeapp_bg"
          alt=""
        >
      </div>
    </div>
    <div class="page_content">
      <div class="section">
        <p class="title">
          JEAPP系统演示
        </p>
        <div class="content_box">
          <div class="video_wrp">
            <video
              ref="video"
              :src="videoUrl"
              preload="auto"
              controls="controls"
              class="video"
            />
            <div
              v-show="videoMask == 'pause'"
              class="video_mask"
              @click="videoPlay"
            >
              <img
                :src="play"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
      <div class="section blue">
        <p class="title">
          一套部署 多平台适用
        </p>
        <p class="subtitle">
          作为专业的企业级移动应用开发平台，JEAPP提供各种在线的App开发工具，可快速开发出适配多平台的移动应用。JEAPP为不同行业的各种企业提供移动端技术解决方案， 服务领域包括互联网、能源、电商、教育、金融、医疗、旅游等行业。
        </p>
        <div class="content_box">
          <div class="apps">
            <div
              v-for="(app, index) in apps"
              :key="index"
            >
              <img
                :src="app.img"
                alt=""
              >
              <p class="name">
                {{ app.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <p class="title">
          JEPaaS平台高效打通复用
        </p>
        <p class="subtitle">
          JEAPP移动应用开发平台支持与JEPaaS开发平台的完美打通，可利用JEPaaS平台的强大开发能力为移动应用的开发快速调取资源，实现双平台资源的高效复用。
        </p>
        <div class="content_box">
          <img
            class="jeapp_1"
            :src="jeapp_1"
            alt=""
          >
        </div>
      </div>
      <div class="section blue">
        <p class="title">
          完善的开发功能
        </p>
        <p class="subtitle short">
          JEAPP使用配置方式可快速开发出APP的多种功能。其中，可进行菜单的快速规划授权，支持功能、插件、图表、报表、链接等多种功能类型，并可通过列表使用自定义HTML快速开发、表单快速配置、查询快速配置的功能开发。
        </p>
        <div class="content_box">
          <div class="tabs_wrp">
            <div class="tablist">
              <div
                v-for="(item, index) in tabs"
                :key="index"
                :class="`${tabActive.title === item.title ? 'active' : ''}`"
                @click="handleTab(item)"
              >
                {{ item.title }}
              </div>
            </div>
            <img
              class="tab_img"
              :src="tabActive.img"
              alt=""
            >
          </div>
        </div>
      </div>
      <div class="section">
        <p class="title">
          丰富的扩展插件
        </p>
        <div class="content_box">
          <img
            :src="components_1"
            class="comimg"
            alt=""
          >
          <img
            :src="components_2"
            class="comimg"
            alt=""
          >
        </div>
      </div>
      <div class="section linear">
        <p class="title">
          全面的服务体系
        </p>
        <p class="subtitle">
          成熟的项目管理体系，灵活的服务模式，JEAPP提供覆盖全面的移动应用快速开发服务体系
        </p>
        <div class="content_box">
          <div class="server_wrp">
            <div class="serverlist">
              <div
                v-for="(item, index) in servers"
                :key="index"
              >
                <img
                  :src="item.img"
                  alt=""
                >
                <p class="name">
                  {{ item.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import jeapp_bg from '@/assets/imgs/product/jeapp/jeapp_bg.png';
import jeapp_1 from '@/assets/imgs/product/jeapp/jeapp_1.png';
import ios from '@/assets/imgs/product/jeapp/ios.png';
import android from '@/assets/imgs/product/jeapp/android.png';
import wx from '@/assets/imgs/product/jeapp/wx.png';
import dd from '@/assets/imgs/product/jeapp/dd.png';
import h5 from '@/assets/imgs/product/jeapp/h5.png';
import components_1 from '@/assets/imgs/product/jeapp/components_1.png';
import components_2 from '@/assets/imgs/product/jeapp/components_2.png';
import server_1 from '@/assets/imgs/product/jeapp/server_1.png';
import server_2 from '@/assets/imgs/product/jeapp/server_2.png';
import server_3 from '@/assets/imgs/product/jeapp/server_3.png';
import server_4 from '@/assets/imgs/product/jeapp/server_4.png';
import tab1 from '@/assets/imgs/product/jeapp/tab1.png';
import tab2 from '@/assets/imgs/product/jeapp/tab2.png';
import tab3 from '@/assets/imgs/product/jeapp/tab3.png';
import videoUrl from '@/assets/imgs/video/jeapp.mp4';
import play from '@/assets/imgs/video/play.png';


export default {
  name: 'ProJeappPage',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      videoUrl,
      play,
      jeapp_bg,
      jeapp_1,
      components_1,
      components_2,
      apps: [
        {
          name: 'IOS',
          img: ios,
        },
        {
          name: 'Android',
          img: android,
        },
        {
          name: '微信',
          img: wx,
        },
        {
          name: '钉钉',
          img: dd,
        },
        {
          name: 'H5',
          img: h5,
        },
      ],
      tabs: [
        {
          title: 'APP功能设计器',
          img: tab1,
        },
        {
          title: '列表 表单 IM',
          img: tab2,
        },
        {
          title: '流程 图表 外勤',
          img: tab3,
        },
      ],
      servers: [
        {
          name: '定制开发',
          img: server_1,
        },
        {
          name: '产品设计',
          img: server_2,
        },
        {
          name: '技术支持',
          img: server_3,
        },
        {
          name: '系统维护迭代升级',
          img: server_4,
        },
      ],
      tabActive: {},
      videoMask: 'pause',
    };
  },
  mounted() {
    const that = this;
    this.tabActive = this.tabs[0];

    const myVideo = document.getElementsByClassName('video')[0];
    myVideo.addEventListener('play', () => {
      that.$set(that, 'videoMask', 'play');
    });

    myVideo.addEventListener('pause', () => {
      that.$set(that, 'videoMask', 'pause');
    });
  },
  methods: {
    openqq() {
      window.open('http://shang.qq.com/wpa/qunwpa?idkey=3d0bc04e6a1e43055b8e69fd8324c477a45fa1cba57cfcd03dc95ba61427c52c');
    },
    handleTab(item) {
      this.tabActive = item;
    },
    videoPlay() {
      this.$refs.video.play();
    },
  },
};
</script>

<style lang="less" scoped>
  .product_jeapp_content {
    .box_top {
      width: 100%;
      height: 600px;
      background:linear-gradient(270deg,rgba(255,125,80,1) 0%,rgba(246,71,77,1) 100%);
      display: flex;
      justify-content: space-between;
      .top_tlite {
        padding: 160px 0 0 195px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        > p {
          padding-left: 180px;
        }
        > .title {
          font-size: 60px;
          margin: 0;
          text-align: left;
        }
        > .subtitle {
          font-size: 60px;
          margin: 20px 0 70px;
          // margin-top: 30px
        }
        > .btn {
          text-align: center;
          box-sizing: border-box;
          margin-left: 180px;
          // margin-top: 80px;
          width:200px;
          height:60px;
          line-height: 60px;
          border:2px solid rgba(255,255,255,1);
          background:linear-gradient(90deg,rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 100%);
          border-radius:6px;
          cursor: pointer;
        }
      }
      .top_img {
        display: flex;
        align-items: flex-end;
        img {
          width: 633px;
          height: 535px;
          position: relative;
          left: -250px;
        }
      }
    }
    .section {
      margin:0 auto;
      padding: 120px 0 100px;
      text-align: center;
      &.blue {
        background: #F7FBFF;
        .subtitle {
          width: 881px;
        }
      }
      &.linear {
        background:linear-gradient(0deg,rgba(255,255,255,1) 0%,rgba(255,239,235,1) 100%);
      }
      .title {
        font-size: 40px;
        color: #000000;
        text-align: center;
        font-weight:bold;
        margin-bottom: 80px;
      }
      .subtitle {
        font-size: 20px;
        color: #303336;
        text-align: center;
        margin: 80px auto 100px;
        width: 1200px;
        &.short {
          width: 880px;
          font-size: 16px;
          color: #000000;
          margin-bottom: 60px;
        }
      }
      .content_box {
        width: 1200px;
        margin: 0 auto;
        .apps {
          display: flex;
          align-items: center;
          justify-content: space-between;
          > div {
            > img {
              width: 160px;
              height: 160px;
            }
            .name {
              width: 160px;
              text-align: center;
              margin: 0;
              margin-top: 30px;
              font-weight: bold;
            }
          }
        }
        .video_wrp {
          width: 900px;
          height: 506px;
          margin: 0 auto;
          position: relative;
          .video {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 900px;
            height: 506px;
            z-index: 1;
            object-fit: contain;
          }
          .video_mask {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            background: rgba(0, 0, 0, .4);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            > img {
              width: 80px;
            }
          }
        }
        .jeapp_1 {
          width: 100%;
          margin: 0 auto;
        }
        .tabs_wrp {
          width: 880px;
          margin: 0 auto;
          .tablist {
            display: flex;
            align-items: center;
            justify-content: center;
            > div {
              width: 290px;
              height: 60px;
              background: #F0F0F0;
              color: #303336;
              font-size: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              &:hover,
              &.active {
                font-weight:bold;
                background: #FF3041;
                color: #ffffff;
              }
            }
          }
          .tab_text {
            width: 880px;
            text-align: center;
            margin: 60px 0 45px;
            height: 40px;
            font-size: 16px;
            color: #000000;
          }
          .tab_img {
            margin-top: 50px;
            width: 100%;
            border-radius:10px;
          }
        }
        .comimg {
          width: 1100px;
          margin: 0 auto;
        }
        .server_wrp {
          .serverlist {
            display: flex;
            align-items: center;
            justify-content: center;
            background:linear-gradient(-90deg,rgba(255,120,84,1) 0%,rgba(246,71,77,1) 100%);
            border-radius: 10px;
            height: 275px;
            > div {
              width: 25%;
              > p {
                margin: 0;
                margin-top: 25px;
                font-size: 20px;
                text-align: center;
                color: #ffffff;
              }
            }
          }
          > p {
            margin: 0;
            margin-top: 80px;
            font-size: 20px;
            color: #000000;
          }
        }
      }
    }
  }
</style>
